import {createApp} from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import router from './router'
import { createPinia } from 'pinia'

const app = createApp(App);
var port = window.location.port;
if(port == '8080'){
	app.config.globalProperties.$hostUrl = 'http://192.168.1.154/price/';
	app.config.globalProperties.$poster = 'https://ps.numcreator.com/';
	app.config.globalProperties.$inter = true;
} else if(port == ''){
	// app.config.globalProperties.$hostUrl = 'https://price.numcreator.com/';
	app.config.globalProperties.$hostUrl = 'https://'+window.location.hostname+'/';
	app.config.globalProperties.$poster = 'https://ps.numcreator.com/';
	app.config.globalProperties.$inter = false;
}

app.use(createPinia());

app.use(router).use(Quasar, quasarUserOptions).mount('#app')