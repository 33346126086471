import { defineStore } from 'pinia';
import useQuasar from 'quasar/src/composables/use-quasar.js';import useMeta from 'quasar/src/composables/use-meta.js';;

export const useState = defineStore('webStore', {
    state: () => {
        return {
            view: false,
            store: 'th',
            currency: 'th',
            theme: false,
            cType: false,
            showARS: false,
            ver: 5.1,
            rate: '0',
            lib: 1,
            loc: {},
            user: {
                // uid: '1',
                // sub: 'U60d965883015593f6e16cd938671e533',
                // username: 'numgamer',
                // picture: 'https://profile.line-scdn.net/0hZJmlPpj2BWVQFRZVau56MmxQCwgnOwMtKHJNBicVXFcudxYwZSZPAnVCC1EuJkVnaHIdACEcXFJ0',
                uid: '',
                sub: '',
                username: '',
                picture: '',
            },
            device: 'home',
            pageName: 'home',
            cardHight: '300px',
            color: '',
            company: '',
            cardHight1:{
                playstation:'255px',
                xbox:'270px',
                nintendo:'255px',
                appletv:'270px',
                steam:'245px',
            },
            cardHight2:{
                playstation:'305px',
                xbox:'370px',
                nintendo:'305px',
                appletv:'350px',
                steam:'245px',
            },
            colors:{
                home:'grey',
                playstation:'blue-8',
                xbox:'green',
                nintendo:'red',
                steam:'blue-grey-8',
                appletv:'grey-10',
                Exchange:'yellow-9',
            },
            shortName:'',
            showHot: false,
            news:'0',
            title:'',
            desc:'',
            image:"",
        }
    },
    actions: {
        getLC(){
            if(localStorage.getItem("price")){
                this.loc = JSON.parse(localStorage.getItem("price"));
                if(!this.loc || this.loc ==undefined || this.loc.theme==undefined || this.loc.view==undefined || this.loc.store==undefined 
                || this.loc.currency==undefined || this.loc.cType==undefined || this.loc.rate==undefined || this.loc.showARS==undefined
                || this.loc.lib==undefined || this.loc.ver==undefined){
                    this.setLC();
                    localStorage.removeItem("user");
                } else {
                    this.theme = this.loc.theme;
                    this.view = this.loc.view;
                    this.store = this.loc.store;
                    this.cType = this.loc.cType;
                    this.showARS = this.loc.showARS;
                    this.lib = this.loc.lib;
                    this.ver = this.loc.ver;
                    this.rate = this.loc.rate;
                    this.currency = this.loc.currency;
                    this.news = this.loc.news==undefined?'0':this.loc.news;
                    const q = useQuasar();
                    if(this.loc.theme) q.dark.toggle();
                    if(localStorage.getItem("user")){
                        this.user = JSON.parse(localStorage.getItem("user"));
                    }
                }
            } else {
                this.setLC();
                localStorage.removeItem("user");
            }
        },
        setLC(){
            let tmp = {
                theme: this.theme,
                view: this.view,
                store: this.store,
                cType: this.cType,
                showARS: this.showARS,
                lib: this.lib,
                rate: this.rate,
                currency: this.currency,
                news: this.news,
                ver: this.ver,
            }
            this.loc = tmp;
            localStorage.setItem("price", JSON.stringify(tmp));
        },
        changeView() {
            this.view = !this.view;
            this.setLC();
            if(this.view){
                this.cardHight = this.cardHight1[this.device];
            } else {
                this.cardHight = this.cardHight2[this.device];
            }
        },
        changeTheme() {
            this.theme = !this.theme;
            this.setLC();
        },
        changeRate(cType,rate,showARS) {
            if(!rate) rate = 0;
            this.cType = cType;
            this.showARS = showARS;
            this.rate = rate;
            this.setLC();
            location.reload();
        },
        changeStore(store,currency) {
            this.store = store;
            this.currency = currency;
            this.setLC();
        },
        changeLib(lib){
            this.lib = lib;
            this.setLC();
        },
        setUser(user){
            this.user = user;
            localStorage.setItem("user", JSON.stringify(user));
        },
        logout(){
            let user = {
                uid: '',
                sub: '',
                username: '',
                picture: '',
            };
            this.user = user;
            localStorage.removeItem("user");
            location.reload();
        },
        setRoute(obj){
            if(obj.indexOf('.')>0){
                this.pageName = obj.split(".")[0];
                this.device = obj.split(".")[1];
            }
            if(this.view){
                this.cardHight = this.cardHight1[this.device];
            } else {
                this.cardHight = this.cardHight2[this.device];
            }
            this.color = this.colors[this.device];
            if(this.device=='home') this.shortName = 'PRICE';
            else if(this.device=='playstation') this.shortName = 'PS';
            else if(this.device=='xbox') this.shortName = 'XB';
            else if(this.device=='nintendo') this.shortName = 'NSW';
            else if(this.device=='steam') this.shortName = 'STEAM';
            else if(this.device=='appletv') this.shortName = 'ATV';
            else if(this.device=='Exchange') this.shortName = 'EXCHANGE';
        },
        setCompany(val){
            this.company = val;
        },
        setNews(val){
            this.news = val;
            this.setLC();
        },

        updateTitle(){
            // if(this.device=='Exchange'){
            //     this.title = "Currency Exchange Rate";
            //     this.desc = "Currency Exchange Rate to Thai Baht USD , ARS , INR , TRY etc.";
            // }
            let metaData = {};
            metaData.title = this.title;
            metaData.meta = {
                keywords: { name: 'keywords', content: 'playstation xbox nintendo appletv price chart thailand' },
                description: { name: "description", content: this.desc },
                ogTitle: { name: "og:title", content: this.title },
                ogDescription: { name: "og:description", content: this.desc },
                ogImage: { name: "og:image", content: this.image },
                ogType: { name: "og:type", content: "website" },
            };
            metaData.link = {
                material: { rel: 'apple-touch-icon', href: "/images/icon_"+this.device+".png" }
            };

            useMeta(metaData);
        }
    }
});